<template>
    <div class="main simple">
        <simple-header />
        <div class="simple-logo">
            <img v-if="step===1" src="@a/img/login-logo.png" width="128" height="50" alt="kamisoul">
            <div v-else class="simple-logo-box">
                <img class="simple-logo-img" src="@a/img/login-logo.png" width="128" height="50" alt="kamisoul">
                <h3 class="simple-title">注册</h3>
            </div>
        </div>
        <div class="simple-main">
            <div class="simple-body">
                <!-- 忘记密码表单 -->
                <el-form ref="ruleForm" :model="ruleForm" class="simple-form">
                    <el-form-item prop="username" :rules="{ required: true, message: '请输入手机号', trigger: 'blur' }">
                        <el-col :span="9">
                            <el-select v-model="ruleForm.mobiletype" placeholder="请选择区号">
                                <el-option
                                    v-for="item in mobiletypes"
                                    :key="item.mobiletype"
                                    :label="item.nation+ '  '+ item.label"
                                    :value="item.mobiletype"
                                >
                                    <span style="float: left">{{ item.nation }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="15">
                            <el-input v-model="ruleForm.username" :maxlength="11" placeholder="手机号" clearable @keyup.native="ruleForm.username=ruleForm.username.replace(/[^\d]/g, '')" />
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="code" :rules="{ required: true, message: '请输入验证码', trigger: 'blur' }">
                        <el-input v-model="ruleForm.code" :maxlength="6" placeholder="验证码" clearable @keyup.native="ruleForm.code=ruleForm.code.replace(/[^\d]/g, '')" @keyup.enter.native="repassword">
                            <a v-if="!countDownDisabled && countDownTime <= 0" slot="append" class="input-append-btn" href="javascript:;" @click="sendMsg">获取验证码</a>
                            <span v-else slot="append">{{ `${countDownTime} 秒后可重新获取` }}</span>
                        </el-input>
                    </el-form-item>
                    <!-- <a v-if="step===1" href="javascript:;" class="btn-primary block" @click="step=2">下一步</a> -->
                    <!-- </el-form> -->
                    <!-- 重设密码表单 -->
                    <!-- <el-form v-else ref="setForm" :model="setForm" class="simple-form"> -->
                    <el-form-item prop="password" :rules="{ required: true, message: '请输入新密码', trigger: 'blur' }">
                        <span slot="label" class="form-label-hastips">新密码<i class="form-label-tips">4~16个字符</i></span>
                        <el-input v-model="ruleForm.password" show-password clearable />
                    </el-form-item>
                    <a href="javascript:;" class="btn-primary block" @click="repassword">提交</a>
                </el-form>
            </div>
        </div>
        <simple-footer />
    </div>
</template>
<script>
// @c an alias to /compontents
import mixin from '@c/mixins'
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import { sendMsg, getMobileType, repassword, logout } from '@api'
export default {
    name: 'Password',
    components: {
        simpleHeader,
        simpleFooter
    },
    mixins: [mixin],
    data() {
        return {
            step: 1,
            ruleForm: {
                mobiletype: '', // 1 中国大陆 2香港 3澳门 4台湾
                username: '',
                code: '',
                password: ''
            },
            timer: null,
            countDownTime: 0,
            countDownDisabled: false,
            mobiletypes: []
        }
    },
    created() {
        // 获取 mobiletypes 信息
        this.getMobileTypes()
        // 获取上次倒计时时间
        const currentTime = new Date().getTime()
        const lastTime = +localStorage.getItem('COUNTDOWN_FORGET_PASSWORD')
        this.countDownTime = Math.floor(60 - (currentTime - lastTime) / 1000)
        console.log('countDownTime', this.countDownTime)
        this.countDown('COUNTDOWN_FORGET_PASSWORD')
    },
    // 实例销毁前清除定时器
    beforeDestroy() {
        // 更新缓存
        if (this.timer) window.clearInterval(this.timer)
    },
    // 退出路由前清除定时器
    beforeRouteLeave(to, from, next) {
        // 导航离开该组件的对应路由时调用
        // 可以访问组件实例 `this`
        if (this.timer) window.clearInterval(this.timer)
        next()
    },
    methods: {
        // 获取mobiletype 信息
        async getMobileTypes() {
            const res = await getMobileType()
            if (res.code === 1001) {
                // console.log(res.detail)
                this.mobiletypes = res.detail
            }
        },
        // 发送短信
        async sendMsg() {
            this.$refs.ruleForm.validateField('username', async error => {
                console.log(error)
                if (!error) {
                    const res = await sendMsg({
                        mobiletype: this.ruleForm.mobiletype,
                        mobile: this.ruleForm.username,
                        type: 3
                    })
                    console.log('res', res)
                    const msg = [
                        '',
                        '发送成功',
                        '发送失败',
                        '该手机号已注册',
                        '60秒内不能多次发送验证码'
                    ]
                    const status = res.detail.status
                    this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: msg[status] || '未知错误', position: 'bottom-right' })
                    if (status !== 1) {
                        // 重设时间
                        this.countDownTime = 0
                        this.countDownDisabled = false
                        // 清除定时器
                        if (this.timer) window.clearInterval(this.timer)
                    } else {
                        // 重设时间
                        this.countDownTime = this.countDownTimeMax
                        // 记录当前时间更新缓存
                        localStorage.setItem('COUNTDOWN_LOGIN', new Date().getTime())
                        // 开始倒计时
                        this.countDown('COUNTDOWN_FORGET_PASSWORD')
                    }
                }
            })
        },
        async logout() {
            const res = await logout()
            if (res.detail.status === '1') {
                this.isLogin = false
                this.$utils.clearUser()
                this.$router.push('/login')
            }
            this.$notify({ title: '提示', type: res.detail.status === '1' ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
        },
        async repassword() {
            this.$refs['ruleForm'].validate(async valid => {
                console.log(valid)
                if (valid) {
                    const res = await repassword(this.ruleForm)
                    console.log('res', res)
                    const status = res.detail.status
                    this.$notify({ title: '提示', type: status === '1' ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
                    if (status === '1') this.logout()
                }
            })
        }
    }
}
</script>
<style scope>
.el-select-dropdown__item{
    padding: 0 13px;
}
.el-col-9 .el-input--mini .el-input__inner{
    border-radius: 4px 0 0 4px;
}
.el-col-15 .el-input__inner{
    border-radius: 0 4px 4px 0;
}
</style>
